<template>
  <div id="app">
    <div class="top-bg">
      <div class="top-nav">
        <div class="title" style="display: flex;align-items: center;">
          <img src="@/assets/image/logo.png" style="width: 70px;"/>
          <div>AI视频监控平台</div>
        </div>
        <div class="nav">
          <router-link to="/">首页</router-link>
          <router-link to="/software">软件平台</router-link>
          <router-link to="/inter">落地芯片</router-link>
          <router-link to="/mall">算法商城</router-link>
          <el-popover
            placement="bottom"
            trigger="hover"
            content="平台登录账号密码，请联系我们后获取">
            <a href="http://39.164.53.248:33089/" target="_blank" style="cursor: pointer;" slot="reference">在线体验</a>
          </el-popover>
          <router-link to="/openSource">代码/工具</router-link>
          <router-link to="/contactUs">联系我们</router-link>
        </div>
        <div class="nav-icon">
          <!-- <img v-if="$route.path=='/'" src="@/assets/image/menu-icon.png" style="width: 35px; height: 35px;" @click="openMenu"/> -->
          <img src="@/assets/image/block-icon.png" style="width: 35px; height: 35px;" @click="openMenu"/>
          <div class="menu" v-if="isOpen">
            <router-link to="/">首页</router-link>
            <router-link to="/software">软件平台</router-link>
            <router-link to="/inter">落地芯片</router-link>
            <router-link to="/mall">算法商城</router-link>
            <!-- <el-popover
              placement="bottom"
              trigger="hover"
              content="平台登录账号密码，请联系我们后获取">
              <a href="http://39.164.53.248:33089/" target="_blank" style="cursor: pointer;" slot="reference">在线体验</a>
            </el-popover>
            <router-link to="/openSource">代码/工具</router-link>
            <router-link to="/contactUs">联系我们</router-link>
             -->
            <el-popover
              placement="bottom"
              trigger="click"
            >
              <div>
                <div>请访问http://39.164.53.248:33089/</div>
                <div>平台登录账号密码，请联系我们后获取</div>
              </div>
              <a slot="reference">在线体验</a>
            </el-popover>
            <router-link to="/openSource">代码/工具</router-link>
            <router-link to="/contactUs">联系我们</router-link>
          </div>
        </div>
      </div>
    </div>
    <router-view/>
    <div class="bottom-bg">
      <div class="bottom-content software-cont">
        <div class="info">
          <div class="info-title">
            YIHECODE
          </div>
          <div class="info-content">
            我们的愿景是在最底层打通各大芯片厂商相互间的壁垒，省去繁琐重复的适配流程，实现芯片、算法、应用的全流程组合，减少企业级应用的开发成本。
          </div>
        </div>
        <div class="links">
          <div class="links-title">
            快速链接
          </div>
          <div class="links-content" @click="gotopage('/')" style="width: auto;min-width: 65px;cursor: pointer;">
            主页
          </div>
          <div class="links-content" @click="gotopage('/software')" style="width: auto;min-width: 65px;cursor: pointer;">
            软件平台
          </div>
          <div class="links-content" @click="gotopage('/inter')" style="width: auto;min-width: 65px;cursor: pointer;">
            落地芯片
          </div>
          <div class="links-content" @click="gotopage('/mall')" style="width: auto;min-width: 65px;cursor: pointer;">
            算法商城
          </div>
          <div class="links-content" @click="goto('http://39.164.53.248:33089/#/login')" style="width: auto;min-width: 65px;cursor: pointer;">
            在线体验
          </div>
          <div class="links-content" @click="gotopage('/openSource')" style="width: auto;min-width: 65px;cursor: pointer;">
            代码/工具
          </div>
          <div class="links-content" @click="gotopage('/contactUs')" style="width: auto;min-width: 65px;cursor: pointer;">
            联系我们
          </div>
        </div>
        <div class="links">
          <div class="links-title">
            相关链接
          </div>
          <div class="links-content" style="width: auto;cursor: pointer;" @click="goto('https://space.bilibili.com/1139333378?spm_id_from=333.337.0.0')">
            Bilil官方号
          </div>
          <div class="links-content" style="width: auto;cursor: pointer;"  @click="goto('https://author.baidu.com/home?from=bjh_article&app_id=1803432442518826')">
            百家号
          </div>
          <div class="links-content" style="width: auto;cursor: pointer;"  @click="goto('https://blog.csdn.net/tt120326?type=blog')">
            CSDN
          </div>
          <div class="links-content" style="width: auto;cursor: pointer;"  @click="goto('https://v.douyin.com/iMAhab1v/')">
            抖音官方号
          </div>
          <div class="links-content" style="width: auto;cursor: pointer;"  @click="goto('https://github.com/YIHECODE-GitHub')">
            Github官方号
          </div>
          <div class="links-content" style="width: auto;cursor: pointer;"  @click="goto('https://gitee.com/moo3108661550')">
            Gitee 官方号
          </div>
        </div>
        <div class="links">
          <div class="links-title">
            联系我们
          </div>
          <div class="links-content" style="width: auto;">
            邮箱：caoliyong@yihecode.com
          </div>
          <div class="links-content" style="width: auto;">
            手机号：18301606030
          </div>
          <div class="links-content" style="width: auto;">
            北京朝阳区东三环中路9号 富尔大厦5层
          </div>
          <div style="margin-top: 10px;">
            <img src="@/assets/image/address.png" style="width: 100%;"/>
          </div>
        </div>
      </div>
      <div class="bottom-line">
        <div class="line-content">YIHECODE© 2024 版权所有</div>
      </div>
      
    </div>
  </div>
</template>
<script>

export default {
    data(){
      return{
        isOpen:false,
      }
    },
    watch: {
      // 监听路由对象$route的变化
      '$route': {
        handler: function () {
          this.isOpen = false
        },
        // 设置为深度监听
        deep: true
      }
    },
    methods:{
      openMenu(){
        this.isOpen = !this.isOpen
      },
      gotopage(url){
        this.$router.push(url)
        window.scrollTo({
          top:0,
          behavior:'smooth'
        })
      },
      goto(url){
        window.open(url)
      }
    }
}
</script>


<style lang="scss">
body{
  margin: 0px;
}
*{
  list-style: none;
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  background:url('@/assets/image/bj.png') no-repeat 0px 0px;
	background-size:cover;
  min-height: 100vh;
}
.top-bg{
  position: fixed;
  top:0px;
  z-index: 999;
  width: 100%;
  background: #FFF;
  box-shadow: 2px 3px 25px rgba(0, 0, 0, 0.1);
}
.top-nav{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  .title{
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-right: 110px;
    padding-left: 10%;
  }
}
.nav {
  a {
    font-size: 18px;
    color: #808396;
    text-decoration:none;
    margin-right: 40px;
    &.router-link-exact-active {
      color: #030F4B;
    }
  }
  a:hover{
    color: #030F4B;
  }
}
.nav-icon{
  display: none;
}
.software-cont{
  padding: 100px 10% 50px 10%;
}
.bottom-bg{
  background: linear-gradient(264.67deg, rgba(255, 81, 47, 0.8) -1.44%, rgba(240, 152, 25, 0.8) 112.15%),
  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  .bottom-content{
    padding-top: 40px;
    display: flex;
    .info{
      margin-right: 100px;
    }
    .info-title{
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.015em;
      text-align: left;
      color: rgba(255, 255, 255, 1);

    }
    .info-content{
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.015em;
      text-align: left;
      color: rgba(213, 213, 213, 1);
      width: 285px;
      margin-top: 10px;

    }
    .links{
      margin-right: 80px;
      .links-title{
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.015em;
        text-align: left;
        color: rgba(255, 255, 255, 1);

      }
      .links-content{
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.015em;
      text-align: left;
      color: rgba(213, 213, 213, 1);
      width: 285px;
      margin-top: 10px;

    }
    }
  }
  .bottom-line{
    margin: 100px 10% 0 10%;
    border-top: 1px solid rgba(213, 213, 213, 1);
    .line-content{
      //styleName: Paragraph_3_Regular;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.015em;
      text-align: left;
      padding-top: 20px;
      color: rgba(213, 213, 213, 1);

    }
  }

}

@media(max-width:1199px){
  .nav{
    display: none;
  }
  .nav-icon{
    display: block;
    padding-right: 10%;
  }
  .top-nav{
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title{
      color: #333333;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 2px;
      margin-right: 0px;
      padding-left: 10%;
    }
  }
  .menu{
    background: #FFF;
    width: 100%;
    position: absolute;
    top: 70px;
    right: 0px;
    padding-bottom: 10px;
    box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.1);
    z-index: 999;
    a {
      font-size: 18px;
      color: #808396;
      text-decoration:none;
      display: block;
      line-height: 40px;
      border-bottom: 1px ridge rgba(255, 255, 255, 0.28);
      &.router-link-exact-active {
        color: #FFF;
        background: #030F4B;
      }
    }
  }
  .bottom-content{
    display: block !important;
  }
}


</style>
